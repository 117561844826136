import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  gameBanner: {
    height: 150,
    margin: theme.spacing(-2, -2, 2, -2),
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    display: 'flex'
  },
  avatarRow: {
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-2)
  },
  backgroundPlaceholder: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.appbar,
    zIndex: -1
  },
  backgroundWrapper: {
    zIndex: 0
  }
}));

export default useStyles;
