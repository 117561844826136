import React from 'react';
import theme from 'theme';
import { SvgIconProps } from '@material-ui/core';

const LinkIcon: React.FC<SvgIconProps> = props => (
  <LinkIcon {...props} style={{ transform: 'rotateY(0deg) rotate(-45deg)' }} />
);

const linkColor = theme.palette.primary.main;

export default LinkIcon;
export { LinkIcon, linkColor };
