import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';

import { listItemTextProps } from './shared';
import SocialIcon, { getSocialColor } from 'components/core/Icons';
import { SocialLink as SocialLinkType } from 'generated/schemaTypes';

// https://discordapp.com/api/invites/{invite.id}

const WhereCard: React.FC<{
  classes: any;
  links: SocialLinkType[];
  show: boolean;
}> = ({ classes, links, show }) => {
  if (!show) return null;

  return (
    <Card className={classes.card}>
      <CardHeader title="Links" className={classes.header} />
      <CardContent>
        <List dense className={classes.list}>
          {links.map(link => (
            <SocialLink link={link} key={link.url} classes={classes} />
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

const SocialLink: React.FC<{ link: any; classes: any }> = ({
  link,
  classes
}) => (
  <ListItem button className={classes.listItem} component="a" href={link.url}>
    <ListItemAvatar>
      <Avatar
        className={classes.icon}
        style={{
          background: getSocialColor(link.type)
        }}
      >
        <SocialIcon
          type={link.type}
          color="action"
          className={classes.customIcon}
        />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primaryTypographyProps={listItemTextProps}
      primary={link.name}
    />
  </ListItem>
);

export default WhereCard;
