import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { history } from 'utils';

import Navigation from 'components/app/Navigation';
import PrivateRoute from 'components/app/PrivateRoute';

import Home from 'pages/Home';
import Profile from 'pages/Profile';
import Friends from 'pages/Friends';
import Gametime from 'pages/Gametime';
import Gametimes from 'pages/Gametimes';
import Notifications from 'pages/Notifications';
import CreateGametime from 'pages/CreateGametime';

const App = () => (
  <Router history={history}>
    <Navigation />
    <Container>
      <Box my={2} component="main">
        <Switch>
          <Route exact path="/" component={Home} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/profile/friends" component={Friends} />
          <PrivateRoute
            exact
            path="/profile/notifications"
            component={Notifications}
          />
          <PrivateRoute exact path="/gametimes" component={Gametimes} />
          <PrivateRoute
            exact
            path="/gametimes/new"
            component={CreateGametime}
          />
          <PrivateRoute path="/gametimes/:id" component={Gametime} />
        </Switch>
      </Box>
    </Container>
  </Router>
);

export default App;
