import React, { useEffect } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';

const PrivateRoute: React.FC<RouteProps> = ({ component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path }
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  if (!isAuthenticated) return <Redirect to="/" />;

  return <Route path={path} component={component} {...rest} />;
};

export default PrivateRoute;
