import React from 'react';
import Paper from '@material-ui/core/Paper';
import { InputBase, IconButton, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: ThemeType) => {
  const light = theme.palette.type === 'light';
  const filledBackgroundColor = light
    ? 'rgba(0, 0, 0, 0.09)'
    : 'rgba(255, 255, 255, 0.09)';
  return {
    search: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: filledBackgroundColor,
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut
      }),
      '&:hover': {
        backgroundColor: light
          ? 'rgba(0, 0, 0, 0.13)'
          : 'rgba(255, 255, 255, 0.13)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: filledBackgroundColor
        }
      },
      '&:focused': {
        backgroundColor: light
          ? 'rgba(0, 0, 0, 0.09)'
          : 'rgba(255, 255, 255, 0.09)'
      },
      '&:disabled': {
        backgroundColor: light
          ? 'rgba(0, 0, 0, 0.12)'
          : 'rgba(255, 255, 255, 0.12)'
      }
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1
    },
    iconButton: {
      padding: 10
    }
  };
});

type SearchInputProps = {
  placeholder: string;
  value: string;
  onChange: (e: any) => void;
  onClick: () => void;
};

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  value,
  onChange,
  onClick
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.search}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        onChange={onChange}
        onKeyPress={e => {
          if (e.key === 'Enter') onClick();
        }}
        value={value}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={onClick}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchInput;
