import React from 'react';
import styled, { css } from 'styled-components';

type SpacerProps = {
  size?: number | string;
  vertical?: boolean;
};

const StyledSpacer = styled.div<SpacerProps>(
  ({ vertical = true, size = 3, theme }) =>
    css({
      height: vertical
        ? typeof size === 'number'
          ? theme.spacing(size)
          : size
        : 0,
      width: vertical
        ? 0
        : typeof size === 'number'
        ? theme.spacing(size)
        : size
    })
);

const Spacer = ({
  size = 3,
  vertical = true,
  hidden = false
}: SpacerProps & { hidden?: boolean }) => {
  if (hidden) return null;

  return <StyledSpacer vertical={vertical} size={size} />;
};

export default Spacer;
