import React from 'react';

import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';

const EmptyGameCard: React.FC = () => (
  <Card>
    <CardHeader title="No Upcoming Games" />
    <CardContent>
      <Typography>Create some or accept some invites to see more.</Typography>
    </CardContent>
  </Card>
);

export default EmptyGameCard;
