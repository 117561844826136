import React from 'react';
import Typography from '@material-ui/core/Typography';

const Home = () => {
  return (
    <section>
      <Typography variant="h1">Raidr</Typography>
    </section>
  );
};

export default Home;
