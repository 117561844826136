import { makeStyles } from '@material-ui/core';

const useCreateGametimeStyles = makeStyles((theme: ThemeType) => ({
  header: {
    background: theme.palette.background.cardHeader
  },
  flex: {
    display: 'flex',
    flexDirection: 'row'
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.background.appbar,
    borderTop: `1px solid ${theme.palette.primary.main}`
  },
  customIcon: {
    height: 20,
    width: 20
  },
  resultList: {
    maxHeight: 300,
    overflowY: 'auto'
  },
  selectedGame: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
    overflow: 'hidden',
    marginBottom: theme.spacing(2),
    position: 'absolute',
    top: 0,
    left: 0,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  selectedGameList: {
    padding: 0
  },
  selectedGameWrapper: {
    height: 210
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  game: {
    position: 'absolute',
    width: '100%',
    padding: theme.spacing(2)
  }
}));

export default useCreateGametimeStyles;
