import { createBrowserHistory } from 'history';

const userIDFromSub = (sub: string): string =>
  sub
    ? sub.indexOf('|') !== -1
      ? sub.substr(sub.indexOf('|') + 1, sub.length)
      : sub
    : '';

const combineDateAndTime = (date: Date, time: Date): Date => {
  const timeString = time.getHours() + ':' + time.getMinutes() + ':00';

  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Jan is 0, dec is 11
  const day = date.getDate();
  const dateString = '' + year + '-' + month + '-' + day;
  const combined = new Date(dateString + ' ' + timeString);

  return combined;
};

const history = createBrowserHistory();

export { userIDFromSub, history, combineDateAndTime };
