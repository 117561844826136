import React from 'react';

import { Link } from 'react-router-dom';

import NextGameCard from './NextGameCard';
import EmptyGameCard from './EmptyGametime';

const NextGametime: React.FC<{ gametime: any; hasNextGame: boolean }> = ({
  gametime,
  hasNextGame
}) => {
  if (!hasNextGame) return <EmptyGameCard />;

  return (
    <NextGameCard
      gametime={gametime}
      actionAreaProps={{
        component: Link,
        to: `/gametimes/${gametime.id}`
      }}
    />
  );
};

export default NextGametime;
