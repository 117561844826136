import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea, {
  CardActionAreaProps
} from '@material-ui/core/CardActionArea';

import { useClippedStyles, useGameStyles } from './gamecard.styles';
import GametimeContent from './GametimeContent';

const GameCard: React.FC<{
  gametime: any;
  actionAreaProps: CardActionAreaProps & { component?: any; to?: string };
}> = ({ gametime, actionAreaProps }) => {
  const classes = useGameStyles();

  return (
    <Card>
      <CardActionArea {...actionAreaProps} className={classes.root}>
        <GametimeContent gametime={gametime} />
        <GameImageCutout src={gametime.game.images.cover} />
      </CardActionArea>
    </Card>
  );
};

const GameImageCutout: React.FC<{ src: string; width?: number }> = ({
  src,
  width
}) => {
  const classes = useClippedStyles(width);
  console.log('src: ', src);
  return (
    <div className={classes.container}>
      <div
        className={classes.clippedImage}
        style={{
          backgroundImage: `url('${src}')`
        }}
      >
        <div className={classes.overlay} />
      </div>
    </div>
  );
};

export default GameCard;

export { GameCard, GameImageCutout };
