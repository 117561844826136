import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Spacer from 'components/core/Spacer';

import {
  CreateGametimeInput,
  SocialLink,
  Game,
  User,
  useCreateGametimeMutation
} from 'generated/schemaTypes';

import {
  SetLinksCard,
  SetGameCard,
  SetDetailsCard,
  SetPeopleCard,
  useCreateGametimeStyles
} from 'components/createGametime';
import { combineDateAndTime } from 'utils';
import { useHistory } from 'react-router-dom';

const CreateGametime = () => {
  const classes = useCreateGametimeStyles();
  const history = useHistory();

  const titleState = useState('');
  const [title] = titleState;

  const dateState = useState(new Date());
  const [date] = dateState;

  const timeState = useState(new Date());
  const [time] = timeState;

  const linksState = useState<SocialLink[]>([]);
  const [links] = linksState;

  const gameState = useState<Game | null>(null);
  const [game] = gameState;

  const usersState = useState<User[]>([]);
  const [users] = usersState;

  const [called, setCalled] = useState(false);

  const [createGametime] = useCreateGametimeMutation();

  const isValid =
    !!title && !!date && !!time && !!game && users.length > 0 && !called;

  const createNewGametime = async () => {
    if (!isValid || !game) return null;

    setCalled(true);

    const gametimeDate = combineDateAndTime(date, time).toString();

    const newGametime: CreateGametimeInput = {
      gametime: {
        title,
        date: gametimeDate,
        gameId: game.id,
        userIds: users.map(u => u.id),
        socialLinks: links
      }
    };

    const createdGametime = await createGametime({
      variables: {
        input: newGametime
      }
    });

    if (
      !createdGametime.data?.createGametime?.gametime ||
      createdGametime.errors
    ) {
      setCalled(false);
      return;
    }

    history.push(
      `/gametimes/${createdGametime.data.createGametime.gametime.id}`
    );
  };

  return (
    <React.Fragment>
      <SetDetailsCard
        classes={classes}
        titleState={titleState}
        dateState={dateState}
        timeState={timeState}
      />
      <Spacer size={3} />
      <SetGameCard classes={classes} gameState={gameState} />
      <Spacer size={3} />
      <SetLinksCard classes={classes} linksState={linksState} />
      <Spacer size={3} />
      <SetPeopleCard classes={classes} usersState={usersState} />
      <Toolbar />
      <AppBar position="fixed" className={classes.appBar} elevation={3}>
        <Toolbar>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={createNewGametime}
          >
            Create Gametime
          </Button>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default CreateGametime;
