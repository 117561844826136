import { TypographyProps } from '@material-ui/core';

const linkTypes = [
  {
    type: 'TWITCH',
    label: 'Twitch',
    prefix: 'https://twitch.tv/',
    placeholder: 'Twitch Channel'
  },
  {
    type: 'MIXER',
    label: 'Mixer',
    prefix: 'https:/mixer.com/',
    placeholder: 'Mixer Channel'
  },
  {
    type: 'DISCORD',
    label: 'Discord',
    prefix: 'https://discord.gg/',
    placeholder: 'Discord Invite'
  },
  {
    type: 'YOUTUBE',
    label: 'YouTube',
    prefix: 'https://youtube.com/channel/',
    placeholder: 'YouTube Channel'
  },
  {
    type: 'FACEBOOK',
    label: 'Facebook',
    prefix: 'https://facebook.com/',
    placeholder: 'Facebook Live Stream'
  },
  {
    type: 'PLAIN',
    label: 'Plain',
    prefix: 'https://',
    placeholder: 'Link URL'
  }
];

const listItemTextProps: TypographyProps = { variant: 'body1' };

export { linkTypes, listItemTextProps };
