import React, { useContext } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Link } from 'react-router-dom';
import { List, Avatar, Typography } from '@material-ui/core';
import { useAuth0 } from 'react-auth0-spa';

import Button from 'components/core/Button';
import Logo from 'assets/logo-no-background.png';

import routes from 'routes';

import { useDrawerStyles } from './navigation.styles';
import { IdentityContext } from 'contexts';

const Drawer: React.FC<{
  toggleDrawer: (toggle: boolean) => void;
  open: boolean;
}> = ({ toggleDrawer, open }) => {
  const classes = useDrawerStyles();
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    loading,
    logout
  } = useAuth0();

  return (
    <SwipeableDrawer
      open={open}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <NavContent
        classes={classes}
        toggleDrawer={toggleDrawer}
        user={user}
        isAuthenticated={isAuthenticated}
        loading={loading}
        login={loginWithRedirect}
        logout={logout}
      />
    </SwipeableDrawer>
  );
};

const NavContent: React.FC<SidebarProps> = ({
  toggleDrawer,
  isAuthenticated,
  login,
  logout,
  loading,
  user,
  classes
}) => {
  const isUserWithAuth = () => isAuthenticated && user;
  const identity = useContext(IdentityContext);

  const isVisibleLink = (route: RouteType) =>
    !route.private || (route.private && isAuthenticated);

  return (
    <div
      className={classes.drawer}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <div className={classes.logoWrapper}>
        <img src={Logo} alt="logo" className={classes.logo} />
        {isUserWithAuth() && (
          <div className={classes.picContainer}>
            <Avatar
              src={identity.me?.img ?? user.picture}
              className={classes.profilePic}
            />
          </div>
        )}
      </div>
      {isUserWithAuth() && (
        <div className={classes.username}>
          <Typography variant="body1" className={classes.username}>
            {user.nickname}
          </Typography>
        </div>
      )}
      <NavLinks
        loading={loading}
        classes={classes}
        toggleDrawer={toggleDrawer}
        isAuthenticated={isAuthenticated}
        isVisibleLink={isVisibleLink}
        login={login}
        logout={logout}
      />
    </div>
  );
};

const NavLinks: React.FC<NavlinkProps> = ({
  classes,
  loading,
  toggleDrawer,
  isAuthenticated,
  isVisibleLink,
  login,
  logout
}) => {
  if (loading) return <div>Loading</div>;

  return (
    <List className={classes.routes}>
      {routes.map(item =>
        isVisibleLink(item) ? (
          <Button
            to={item.link}
            onClick={() => toggleDrawer(false)}
            component={Link}
            key={item.label}
            variant="outlined"
            color="primary"
            text="primary"
            fullWidth
          >
            {item.label}
          </Button>
        ) : null
      )}
      {isAuthenticated ? (
        <Button
          onClick={logout}
          variant="contained"
          color="primary"
          text="primary"
          fullWidth
        >
          Logout
        </Button>
      ) : (
        <Button
          onClick={login}
          variant="contained"
          color="primary"
          text="primary"
          fullWidth
        >
          Login
        </Button>
      )}
    </List>
  );
};

export default Drawer;
