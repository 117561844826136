import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
const DARK = 'dark';

const customTheme: Partial<ThemeOptions> & {
  palette: { background: { appbar: string; cardHeader: string } };
} = {
  palette: {
    primary: {
      main: '#9B51E0'
    },
    secondary: {
      main: '#19857B'
    },
    background: {
      appbar: '#1D1E28',
      cardHeader: '#121212',
      default: '#313641',
      paper: '#1C1E23'
    },
    type: DARK
  }
};

// A custom theme for this app
const theme = createMuiTheme(customTheme);

export default theme;
export { theme, customTheme };
