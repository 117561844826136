import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationIcon from '@material-ui/icons/Notifications';
import Button from 'components/core/Button';

import { useBarStyles } from './navigation.styles';

const Bar: React.FC<{ toggleDrawer: (toggle: boolean) => void }> = ({
  toggleDrawer
}) => {
  const classes = useBarStyles();

  return (
    <AppBar className={classes.bar} position="fixed">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Open drawer"
          className={classes.menuButton}
          onClick={() => toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.title}>
          <Typography
            variant="h6"
            component={Link}
            className={classes.link}
            to="/"
          >
            Raidr
          </Typography>
        </div>
        <Button
          to="/gametimes/new"
          component={Link}
          variant="outlined"
          color="primary"
          text="primary"
        >
          Create Gametime
        </Button>
        <Link to="/profile/notifications">
          <IconButton>
            <NotificationIcon />
          </IconButton>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default Bar;
