import { useReducer } from 'react';
import {
  CHANGE_LINK_TYPE,
  CHANGE_LINK_VALUE,
  CLEAR_LINK
} from './links.actions';

const INITIAL_STATE = {
  type: 'TWITCH',
  label: 'Twitch',
  prefix: 'https://twitch.tv/',
  placeholder: 'Twitch Channel',
  value: ''
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case CLEAR_LINK:
      return { ...INITIAL_STATE };
    case CHANGE_LINK_VALUE:
      return { ...state, value: action.payload };
    case CHANGE_LINK_TYPE:
      return {
        ...state,
        value: '',
        type: action.payload.type,
        prefix: action.payload.prefix,
        placeholder: action.payload.placeholder
      };
    default:
      throw new Error();
  }
}

const useLinkReducer = () => useReducer(reducer, INITIAL_STATE);

export default useLinkReducer;
