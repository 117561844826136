import React from 'react';

import {
  Card,
  CardHeader,
  CardActionArea,
  CardActionAreaProps
} from '@material-ui/core';

import GameHeader from './GameHeader';
import { GametimeContent } from 'components/core/GameCard';
import { useNextGameStyles } from './gametimes.styles';
import { Gametime } from 'generated/schemaTypes';

const NextGameCard: React.FC<{
  gametime: Gametime;
  actionAreaProps: CardActionAreaProps & { component?: any; to?: string };
}> = ({ gametime, actionAreaProps }) => {
  const classes = useNextGameStyles();
  const coverSet = {
    med: gametime.game.images?.screenshotMed,
    big: gametime.game.images?.screenshotBig,
    huge: gametime.game.images?.screenshotHuge
  };

  return (
    <GameHeader imageSet={coverSet}>
      <Card className={classes.root}>
        <CardHeader title="Next Game" className={classes.header} />
        <CardActionArea {...actionAreaProps} className={classes.actionArea}>
          <GametimeContent gametime={gametime} />
        </CardActionArea>
      </Card>
    </GameHeader>
  );
};

export default NextGameCard;
