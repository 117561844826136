import React, { useState } from 'react';

import {
  Box,
  Card,
  List,
  Avatar,
  ListItem,
  IconButton,
  CardHeader,
  CardContent,
  ListItemText,
  ListItemAvatar,
  LinearProgress,
  ListItemSecondaryAction
} from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';

import SearchInput from 'components/core/SearchInput';

import { listItemTextProps } from './shared';
import {
  Game,
  useSearchGamesLazyQuery,
  Maybe,
  GameImage
} from 'generated/schemaTypes';

type SearchGamesResultType = Maybe<
  (Pick<Game, 'id' | 'name'> & {
    images: Maybe<
      Pick<
        GameImage,
        | 'cover'
        | 'logo'
        | 'thumb'
        | 'screenshotMed'
        | 'screenshotBig'
        | 'screenshotHuge'
        | 'res720'
        | 'res1080'
      >
    >;
  })[]
>;

const SetGameCard: React.FC<{ gameState: any; classes: any }> = ({
  gameState,
  classes
}) => {
  const [game, setGame] = gameState;
  const [data, setData] = useState<SearchGamesResultType>(null);

  const [getGames, { called, loading, error }] = useSearchGamesLazyQuery({
    onCompleted: data => setData(data?.gamesBySearch)
  });

  const [search, setSearch] = useState('');

  const searchGames = () => {
    getGames({
      variables: { search }
    });
  };

  const deselectGame = () => {
    setGame(null);
    setSearch('');
  };

  const selectGame = (g: any) => {
    setData(null);
    setSearch('');
    setGame(g);
  };

  return (
    <Card>
      <CardHeader title="Game" className={classes.header} />
      <CardContent style={{ position: 'relative' }}>
        {!game && (
          <SearchInput
            placeholder="Search Games"
            onChange={e => setSearch(e.target.value)}
            value={search}
            onClick={searchGames}
          />
        )}
        {game && (
          <SelectedGame
            game={game}
            deselectGame={deselectGame}
            classes={classes}
          />
        )}
      </CardContent>
      <SearchResults
        show={called && !!search && !!data && !game}
        loading={loading}
        error={error}
        data={data}
        selectGame={selectGame}
        classes={classes}
      />
    </Card>
  );
};

const SelectedGame: React.FC<{
  game: Game;
  deselectGame: any;
  classes: any;
}> = ({ game, classes, deselectGame }) => {
  return (
    <div className={classes.selectedGameWrapper}>
      <div className={classes.selectedGame}>
        <img
          className={classes.img}
          srcSet={`${game.images?.screenshotMed} 569w, ${game.images?.screenshotBig} 889w, ${game.images?.screenshotHuge} 1280w`}
          src={game.images?.screenshotMed ?? ''}
          alt={game.name}
        />
        <div className={classes.game}>
          <Card>
            <CardContent>
              <List dense className={classes.selectedGameList}>
                <ListItem>
                  <ListItemText
                    primary={game.name}
                    primaryTypographyProps={listItemTextProps}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={deselectGame}>
                      <TrashIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

const SearchResults: React.FC<{
  show: boolean;
  loading: boolean;
  error: any;
  data: SearchGamesResultType;
  selectGame: any;
  classes: any;
}> = ({ show, loading, error, data, selectGame, classes }) => {
  if (!show || !data) return null;
  if (loading)
    return (
      <Box m={2}>
        <LinearProgress />
      </Box>
    );
  if (error) return <span>{error}</span>;

  return (
    <List className={classes.resultList}>
      {data.map(game => (
        <ListItem key={game.id} button onClick={() => selectGame(game)}>
          <ListItemAvatar>
            <Avatar src={game.images?.logo ?? ''} />
          </ListItemAvatar>
          <ListItemText primary={game.name} />
        </ListItem>
      ))}
    </List>
  );
};

export default SetGameCard;
