import React from 'react';
import { Card, CardHeader, CardContent, TextField } from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';

const SetDetailsCard: React.FC<{
  classes: any;
  titleState: any;
  timeState: any;
  dateState: any;
}> = ({ classes, titleState, timeState, dateState }) => {
  const [title, setTitle] = titleState;
  const [selectedDate, handleDateChange] = dateState;
  const [selectedTime, handleTimeChange] = timeState;
  return (
    <Card>
      <CardHeader title="Details" className={classes.header} />
      <CardContent>
        <TextField
          label="Title"
          placeholder="Gametime Title"
          fullWidth
          margin="normal"
          onChange={e => setTitle(e.target.value)}
          value={title}
          InputLabelProps={{
            shrink: true
          }}
        />
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          fullWidth
          margin="normal"
        />
        <TimePicker
          value={selectedTime}
          onChange={handleTimeChange}
          fullWidth
          margin="normal"
        />
      </CardContent>
    </Card>
  );
};

export default SetDetailsCard;
