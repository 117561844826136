import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  LinearProgress,
  ListItemAvatar,
  ListItemText,
  List,
  ListItem,
  Box,
  Avatar,
  IconButton,
  ListItemIcon
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import SearchInput from 'components/core/SearchInput';
import {
  User,
  useSearchFriendsLazyQuery,
  SearchFriendsQuery
} from 'generated/schemaTypes';

const SetPeopleCard: React.FC<{ usersState: any; classes: any }> = ({
  usersState,
  classes
}) => {
  const [users, setUsers] = usersState;
  const [data, setData] = useState<SearchFriendsQuery | null>(null);

  const [search, setSearch] = useState('');

  const [getUsers, { called, loading, error }] = useSearchFriendsLazyQuery({
    onCompleted: data => {
      console.log('data', data);
      setData(data);
    }
  });

  const selectUser = (u: User) => {
    setData(null);
    setSearch('');
    if (users.find((user: User) => user.id === u.id)) return;
    setUsers((users: User[]) => [...users, u]);
  };

  const deselectUser = (u: User) => {
    setUsers((users: User[]) => users.filter((user: User) => user.id !== u.id));
  };

  const searchUsers = () => {
    getUsers({
      variables: { search }
    });
  };

  return (
    <Card>
      <CardHeader title="People" className={classes.header} />
      <CardContent>
        <SearchInput
          placeholder="Search Friends"
          onChange={e => setSearch(e.target.value)}
          value={search}
          onClick={searchUsers}
        />
      </CardContent>
      <SearchResults
        show={called && !!search}
        loading={loading}
        error={error}
        data={data}
        selectUser={selectUser}
        classes={classes}
      />
      <SelectedUsers
        show={!!users && users.length > 0}
        users={users}
        deselectUser={deselectUser}
        classes={classes}
      ></SelectedUsers>
    </Card>
  );
};

const SearchResults: React.FC<{
  show: boolean;
  loading: boolean;
  error: any;
  data: SearchFriendsQuery | null;
  selectUser: any;
  classes: any;
}> = ({ show, loading, error, data, selectUser, classes }) => {
  console.log('RESULTS: ', data);
  if (!show) return null;
  if (loading)
    return (
      <Box m={2}>
        <LinearProgress />
      </Box>
    );
  if (error) return <span>error: {error}</span>;
  if (!data?.me?.friends) return null;

  const users = data.me.friends;

  return (
    <List
      className={classes.resultList}
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.09)' }}
    >
      {users.map(user => (
        <ListItem key={user.id}>
          <ListItemAvatar>
            <Avatar src={user.img} />
          </ListItemAvatar>
          <ListItemText primary={user.username} />
          <ListItemIcon>
            <IconButton onClick={() => selectUser(user)}>
              <CheckIcon />
            </IconButton>
          </ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
};

const SelectedUsers: React.FC<{
  show: boolean;
  users: User[];
  deselectUser: (user: User) => void;
  classes: any;
}> = ({ show, users, deselectUser, classes }) => {
  if (!show) return null;
  console.log('HERE: ', users);
  return (
    <List className={classes.resultList}>
      {users.map(user => (
        <ListItem key={user.id}>
          <ListItemAvatar>
            <Avatar src={user.img} />
          </ListItemAvatar>
          <ListItemText primary={user.username} />
          <ListItemIcon>
            <IconButton onClick={() => deselectUser(user)}>
              <ClearIcon />
            </IconButton>
          </ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
};

export default SetPeopleCard;
