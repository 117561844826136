import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginTop: theme.spacing(-2)
  },
  fab: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 5,
    margin: theme.spacing(2)
  }
}));

const useGameHeaderStyles = makeStyles((theme: ThemeType) => ({
  root: (props: {
    position:
      | 'fixed'
      | 'absolute'
      | 'relative'
      | 'static'
      | 'sticky'
      | undefined;
  }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
    overflow: 'hidden',
    marginBottom: theme.spacing(2),
    position: props.position ? props.position : 'relative',
    top: props.position && props.position === 'fixed' ? 56 : 0,
    left: 0,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  }),
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  game: {
    position: 'absolute',
    width: '100%',
    padding: theme.spacing(2)
  }
}));

const useNextGameStyles = makeStyles((theme: ThemeType) => ({
  root: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    boxShadow: '10px 10px 20px -5px rgba(0,0,0,0.5)'
  },
  header: {
    background: theme.palette.background.cardHeader,
    padding: theme.spacing(1, 2)
  },
  actionArea: {
    height: 100
  }
}));

export default useStyles;
export { useStyles, useGameHeaderStyles, useNextGameStyles };
