import React from 'react';
import { Auth0Provider } from 'react-auth0-spa';
import { history } from 'utils';

const onRedirectCallback = (appState: any) => {
  history.push(appState?.targetUrl ?? window.location.pathname);
};

const withAuth = (InputComponent: React.FC) => {
  const EnhancedComponent: React.FC = () => {
    return (
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <InputComponent />
      </Auth0Provider>
    );
  };

  return EnhancedComponent;
};

export default withAuth;
