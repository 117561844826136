import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650
  }
}));

const RecentlyPlayed = ({ loading, data }) => {
  const classes = useStyles();

  if (loading) return <LinearProgress />;

  const gametimes = data.me.gametimes;

  if (!gametimes)
    return (
      <Paper>
        <Typography>
          No Gametimes. Come back after scheduling some time with friends!
        </Typography>
      </Paper>
    );

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Game</TableCell>
          <TableCell>Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.me.gametimes?.map(row => (
          <TableRow key={row.id}>
            <TableCell component="th" scope="row">
              {row.title}
            </TableCell>
            <TableCell>{row.game.name}</TableCell>
            <TableCell>
              {row.date} {row.time}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default RecentlyPlayed;
