import DiscordIcon from './DiscordIcon';
import MixerIcon from './MixerIcon';
import TwitchIcon from './TwitchIcon';
import YoutubeIcon from './YoutubeIcon';
import FacebookIcon from './FacebookIcon';
import LinkIcon from './LinkIcon';

import SocialIcon, { getSocialColor } from './SocialIcon';

export default SocialIcon;

export {
  DiscordIcon,
  MixerIcon,
  TwitchIcon,
  YoutubeIcon,
  LinkIcon,
  FacebookIcon,
  SocialIcon,
  getSocialColor
};
