import React from 'react';

import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const AddGametimeFAB: React.FC<{ classes: any }> = ({ classes }) => {
  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  };

  return (
    <div className={classes.fab}>
      <Zoom
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exit}ms`
        }}
        in
        unmountOnExit
      >
        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/gametimes/new"
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </div>
  );
};

export default AddGametimeFAB;
