import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Bar from './Bar';
import Drawer from './Drawer';

const Navigation: React.FC = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open: boolean) => {
    setOpen(open);
  };

  return (
    <React.Fragment>
      <Bar toggleDrawer={toggleDrawer} />
      <Toolbar />
      <Drawer toggleDrawer={toggleDrawer} open={open} />
    </React.Fragment>
  );
};
export default Navigation;
