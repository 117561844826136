import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import RecentlyPlayed from './RecentlyPlayed';
import { useGetRecentGamesQuery } from 'generated/schemaTypes';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  tables: {
    overflowX: 'auto'
  }
}));

const tables = ['Recent Games', 'Most Played Games'];

const Tables = () => {
  const classes = useStyles();
  const { loading, data } = useGetRecentGamesQuery();
  const [table, setTable] = useState('Recent Games');

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <TextField
          select
          fullWidth
          margin="normal"
          value={table}
          onChange={event => setTable(event.target.value)}
        >
          {tables.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Toolbar>
      <div className={classes.tables}>
        {table === 'Recent Games' && (
          <RecentlyPlayed loading={loading} data={data} />
        )}
        {table === 'Most Played Games' && <div>Apex Legends</div>}
      </div>
    </Paper>
  );
};

export default Tables;
