import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';

import theme from 'theme';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import * as serviceWorker from 'serviceWorker';

import App from 'App';
import { IdentityContext } from 'contexts';
import { withApollo, withAuth } from 'hocs';
import { useGetIdentityQuery } from 'generated/schemaTypes';

const Index: React.FC = () => {
  const { loading, error, data } = useGetIdentityQuery();
  return (
    <IdentityContext.Provider value={{ loading, error, me: data?.me }}>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CssBaseline />
            <App />
          </MuiPickersUtilsProvider>
        </StyledThemeProvider>
      </ThemeProvider>
    </IdentityContext.Provider>
  );
};

const EnhancedIndex = withAuth(withApollo(Index));

ReactDOM.render(<EnhancedIndex />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
