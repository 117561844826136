import React from 'react';

import Loader from 'components/core/Loader';

import {
  NextGametime,
  UpcomingGametimes,
  AddGametimeFAB,
  useStyles
} from 'components/gametimes';
import { Box } from '@material-ui/core';
import { useGetUpcomingGametimesQuery } from 'generated/schemaTypes';

const Gametimes: React.FC = () => {
  const classes = useStyles();

  const { loading, error, data } = useGetUpcomingGametimesQuery();

  if (loading) return <Loader loading={true} />;
  if (error) return <span>Error! ${error.message}</span>;

  const gametimes = data?.me?.gametimes;

  const nextGametime = gametimes?.[0];
  const upcomingGametimes = gametimes?.slice(1) ?? [];

  const hasNextGame = !!nextGametime;
  const hasUpcomingGames = upcomingGametimes.length >= 1;

  return (
    <React.Fragment>
      <Box marginX={-2} marginTop={-2}>
        <NextGametime gametime={nextGametime} hasNextGame={hasNextGame} />
      </Box>
      <UpcomingGametimes
        gametimes={upcomingGametimes}
        hasUpcomingGames={hasUpcomingGames}
      />
      <AddGametimeFAB classes={classes} />
    </React.Fragment>
  );
};

export default Gametimes;
