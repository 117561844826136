import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import TrashIcon from '@material-ui/icons/Delete';
import Loader, { ListLoader } from 'components/core/Loader';
import Spacer from 'components/core/Spacer';
import {
  User,
  Gametime,
  Game,
  Maybe,
  GameImage,
  useAcceptGametimeInviteMutation,
  InviteResponse,
  useGetGametimeInvitesQuery
} from 'generated/schemaTypes';
import { useHistory } from 'react-router-dom';

type GametimeInvitesResultType =
  | {
      user: Pick<User, 'id' | 'username' | 'img'>;
      gametime: Pick<Gametime, 'id' | 'title'> & {
        game: Pick<Game, 'id' | 'name'> & {
          images: Maybe<Pick<GameImage, 'thumb'>>;
        };
      };
    }[]
  | null
  | undefined;

const Notifications = () => {
  const { loading: authLoading } = useAuth0();

  const { loading, data } = useGetGametimeInvitesQuery();

  if (authLoading) return <Loader loading />;

  const invites = data?.me?.gametimeInvites;

  return (
    <>
      <Typography variant="h5">Gametime Invites</Typography>
      <Spacer />
      <InviteList invites={invites} loading={loading} />
    </>
  );
};

const InviteList: React.FC<{
  loading: boolean;
  invites: GametimeInvitesResultType;
}> = ({ loading, invites }) => {
  const history = useHistory();

  const [
    acceptGametimeInvite,
    { loading: acceptGametimeLoading, error: acceptGametimeError }
  ] = useAcceptGametimeInviteMutation();

  const acceptGametime = async (id: string, accept: boolean = true) => {
    const updatedGametime = await acceptGametimeInvite({
      variables: {
        input: {
          gametimeId: id,
          response: accept ? InviteResponse.Accept : InviteResponse.Decline
        }
      }
    });
    if (accept && updatedGametime.data?.acceptGametimeInvite?.gametime?.id) {
      history.push(
        `/gametimes/${updatedGametime.data.acceptGametimeInvite.gametime.id}`
      );
    }
  };

  if (acceptGametimeLoading) {
    return <Loader loading />;
  }

  if (loading) {
    return <ListLoader loading />;
  }

  if (acceptGametimeError) {
    return <span>{acceptGametimeError}</span>;
  }

  if (!invites || invites.length === 0) {
    return (
      <Paper>
        <List>
          <ListItem>
            <ListItemText primary="No invites" />
          </ListItem>
        </List>
      </Paper>
    );
  }

  return (
    <Paper>
      <List>
        {invites.map(invite => (
          <ListItem key={invite.gametime.id}>
            <ListItemAvatar>
              <Avatar src={invite.gametime.game.images?.thumb ?? ''}></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={invite.gametime.title}
              secondary={invite.gametime.game.name}
            />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => acceptGametime(invite.gametime.id, false)}
              >
                <TrashIcon />
              </IconButton>
              <IconButton onClick={() => acceptGametime(invite.gametime.id)}>
                <CheckIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default Notifications;
