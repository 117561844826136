import React, { useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import SearchInput from 'components/core/SearchInput';
import Spacer from 'components/core/Spacer';
import {
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
  Typography,
  Box
} from '@material-ui/core';
import Loader, { ListLoader } from 'components/core/Loader';
import { useAuth0 } from 'react-auth0-spa';
import {
  useSendFriendRequestMutation,
  useSearchUsersLazyQuery,
  CreateFriendRequestInput,
  useGetFriendsAndRequestsQuery,
  Maybe,
  User,
  FriendRequest,
  SearchUsersQuery,
  useAcceptFriendRequestMutation,
  AcceptFriendRequestInput
} from 'generated/schemaTypes';
import { useHistory } from 'react-router-dom';

type FriendRequestResultType =
  | (Pick<FriendRequest, 'status' | 'lastModified'> & {
      user: Pick<User, 'img' | 'id' | 'username'>;
    })[]
  | null
  | undefined;

type FriendSearchResultType = Maybe<Pick<User, 'img' | 'id' | 'username'>[]>;

const Friends = () => {
  const { loading } = useAuth0();
  const history = useHistory();
  const [search, setSearch] = useState('');

  const [addFriend] = useSendFriendRequestMutation();

  const [
    doQuery,
    { loading: usersLoading, data: usersData, called: usersCalled }
  ] = useSearchUsersLazyQuery();

  const sendFriendRequest = (friendId: any) => {
    const input: CreateFriendRequestInput = {
      friendId
    };

    addFriend({
      variables: {
        input
      }
    });

    history.push(`/profile`);
  };

  if (loading) return <Loader loading></Loader>;

  const searchFriends = () => {
    doQuery({
      variables: {
        search
      }
    });
  };

  return (
    <>
      <SearchInput
        placeholder="Search Users"
        value={search}
        onChange={(e: any) => setSearch(e.target.value)}
        onClick={searchFriends}
      />

      <Spacer size={3} />

      <SearchResults
        called={usersCalled}
        loading={usersLoading}
        data={usersData}
        addFriend={sendFriendRequest}
      />
      <Spacer size={3} hidden={!usersCalled} />
      <FriendsLists />
    </>
  );
};

const FriendList: React.FC<{
  friends: FriendSearchResultType;
}> = ({ friends }) => {
  if (!friends || friends.length === 0)
    return (
      <React.Fragment>
        <Typography variant="h5">My Friends</Typography>
        <Spacer />
        <Paper>
          <Box p={2}>
            <Typography>No Friends. Add friends above!</Typography>
          </Box>
        </Paper>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <Typography variant="h5">My Friends</Typography>
      <Spacer />
      <Paper>
        <List>
          {friends.map(user => (
            <ListItem key={user.id}>
              <ListItemAvatar>
                <Avatar src={user.img}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.username} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </React.Fragment>
  );
};

const SentRequests: React.FC<{
  sentRequests: FriendRequestResultType;
}> = ({ sentRequests }) => {
  if (!sentRequests || sentRequests.length === 0) return null;

  return (
    <React.Fragment>
      <Typography variant="h5">Sent Requests</Typography>
      <Spacer />
      <Paper>
        <List>
          {sentRequests.map(req => (
            <ListItem key={req.user.id}>
              <ListItemAvatar>
                <Avatar src={req.user.img}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={req.user.username} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </React.Fragment>
  );
};

const RecievedRequests: React.FC<{
  recievedRequests: FriendRequestResultType;
}> = ({ recievedRequests }) => {
  const [acceptRequest] = useAcceptFriendRequestMutation();
  const history = useHistory();
  const acceptFriendRequest = (friendId: string) => {
    const input: AcceptFriendRequestInput = {
      friendId
    };

    acceptRequest({
      variables: {
        input
      }
    });

    history.push(`/profile`);
  };

  if (!recievedRequests || recievedRequests.length === 0) return null;

  return (
    <React.Fragment>
      <Typography variant="h5">Recieved Requests</Typography>
      <Spacer />
      <Paper>
        <List>
          {recievedRequests.map(req => (
            <ListItem key={req.user.id}>
              <ListItemAvatar>
                <Avatar src={req.user.img}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={req.user.username} />
              <ListItemSecondaryAction>
                <IconButton onClick={() => acceptFriendRequest(req.user.id)}>
                  <CheckIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </React.Fragment>
  );
};

const FriendsLists: React.FC = () => {
  const { loading, data, error } = useGetFriendsAndRequestsQuery();

  if (loading) return <ListLoader loading />;
  if (error || !data?.me) return <span>no me?</span>;

  const { friends, friendRequests } = data.me;

  return (
    <React.Fragment>
      <Spacer />
      <FriendList friends={friends} />
      <Spacer />
      <SentRequests sentRequests={friendRequests?.sentRequests} />
      <Spacer />
      <RecievedRequests recievedRequests={friendRequests?.recievedRequests} />
    </React.Fragment>
  );
};

const SearchResults: React.FC<{
  loading: boolean;
  called: boolean;
  addFriend: any;
  data: SearchUsersQuery | undefined;
}> = ({ loading, called, addFriend, data }) => {
  if (!called) return null;

  if (loading) return <ListLoader loading />;

  if (!data || !data.usersBySearch) return <span>no data</span>;

  return (
    <React.Fragment>
      <Typography variant="h5">Search Results</Typography>
      <Paper>
        <List>
          {data.usersBySearch.map(user => (
            <ListItem key={user.id}>
              <ListItemAvatar>
                <Avatar src={user.img}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.username} />
              <ListItemSecondaryAction>
                <IconButton onClick={() => addFriend(user.id)}>
                  <CheckIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </React.Fragment>
  );
};

export default Friends;
