import React from 'react';

import Spacer from 'components/core/Spacer';
import { Link } from 'react-router-dom';

import { ProfileHeader, Tables } from 'components/profile';
import { Button } from '@material-ui/core';
import useStyles from 'components/profile/profile.styles';

const Profile = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <ProfileHeader classes={classes} />
      <Spacer size={3} hidden={true} />
      <ProfileInfo />
      <Spacer size={3} hidden={true} />
      <MostPlayed />
      <Spacer size={3} />
      {/* <Statistics />
      <Spacer size="20" /> */}
      <Tables />
    </React.Fragment>
  );
};

const ProfileInfo = () => (
  <Button to="/profile/friends" component={Link}>
    Friends
  </Button>
);
const MostPlayed = () => null;

export default Profile;
