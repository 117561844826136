import { makeStyles } from '@material-ui/core';

const useBarStyles = makeStyles((theme: ThemeType) => ({
  title: {
    flexGrow: 1
  },
  link: {
    textDecoration: 'inherit',
    color: 'inherit'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  bar: {
    backgroundColor: theme.palette.background.appbar
  }
}));

const useDrawerStyles = makeStyles((theme: ThemeType) => ({
  drawer: {
    width: 250
  },
  routes: {
    height: '100%',
    padding: theme.spacing(1),
    '& a, button': {
      margin: theme.spacing(1, 0)
    }
  },
  logoWrapper: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  logo: {
    maxWidth: 200,
    height: 200
  },
  picContainer: {
    height: 60,
    width: 60,
    position: 'absolute',
    bottom: 0,
    right: 0,
    marginRight: 20,
    marginBottom: 20
  },
  profilePic: {
    height: '100%',
    width: '100%'
  },
  username: {
    width: '100%',
    padding: theme.spacing(1),
    background: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export { useBarStyles, useDrawerStyles };
