import React from 'react';

import { useGameHeaderStyles } from './gametimes.styles';

const GameHeader: React.FC<{
  imageSet: any;
  position?: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky';
}> = ({ imageSet, position, ...props }) => {
  const classes = useGameHeaderStyles({ position });

  return (
    <div className={classes.root}>
      <img
        className={classes.img}
        srcSet={`${imageSet.med} 569w, ${imageSet.big} 889w, ${imageSet.huge} 1280w`}
        src={imageSet.med}
        alt="next game"
      />
      <div className={classes.game}>{props.children}</div>
    </div>
  );
};

export default GameHeader;
