import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Avatar } from '@material-ui/core';
import { useContentStyles } from './gamecard.styles';

const GametimeContent: React.FC<{ gametime: any }> = ({ gametime }) => {
  const classes = useContentStyles();
  const date = new Date(gametime.date);
  return (
    <CardContent className={classes.root}>
      <div className={classes.details}>
        <Typography variant="h6" className={classes.title}>
          {gametime.title}
        </Typography>
        <div className={classes.time}>
          <Typography variant="body2">{date.toLocaleDateString()}</Typography>
          <Typography variant="body2">{date.toLocaleTimeString()}</Typography>
        </div>
      </div>
      <GametimeAvatars users={gametime.users} classes={classes} />
    </CardContent>
  );
};

const GametimeAvatars: React.FC<{ users: any[]; classes: any }> = ({
  users,
  classes
}) => (
  <div className={classes.avatars}>
    {users.slice(0, 3).map((u, i) => (
      <Avatar
        className={classes.avatar}
        key={`${u.user.img}-${i}`}
        src={u.user.img}
      />
    ))}
    {users.length > 3 && (
      <Avatar className={`${classes.avatar} ${classes.extra}`}>
        <Typography variant="caption">+{users.length - 3}</Typography>
      </Avatar>
    )}
  </div>
);

export default GametimeContent;
