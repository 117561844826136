import React from 'react';

import { useTheme, Theme } from '@material-ui/core/styles';
import {
  Tab,
  Tabs,
  Card,
  List,
  AppBar,
  Avatar,
  ListItem,
  Typography,
  CardContent,
  ListItemText,
  ListItemAvatar
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

import { listItemTextProps } from './shared';

const WhoCard: React.FC<{ classes: any; users: any[] }> = ({
  classes,
  users
}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const invited = users.filter(u => u.status === 'INVITED');
  const accepted = users.filter(u => u.status === 'ACCEPTED');
  const declined = users.filter(u => u.status === 'DECLINED');

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Card className={classes.card}>
      <TabHeader value={value} onChange={handleChange} classes={classes} />
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <InviteTab
          value={value}
          index={0}
          theme={theme}
          classes={classes}
          users={accepted}
        />
        <InviteTab
          value={value}
          index={1}
          theme={theme}
          classes={classes}
          users={invited}
        />
        <InviteTab
          value={value}
          index={2}
          theme={theme}
          classes={classes}
          users={declined}
        />
      </SwipeableViews>
    </Card>
  );
};

const TabHeader: React.FC<{ value: any; onChange: any; classes: any }> = ({
  value,
  onChange,
  classes
}) => (
  <AppBar position="static" className={classes.header}>
    <Tabs
      value={value}
      onChange={onChange}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      aria-label="full width tabs example"
    >
      <Tab label="Accepted" {...a11yProps(0)} />
      <Tab label="Invited" {...a11yProps(1)} />
      <Tab label="Declined" {...a11yProps(2)} />
    </Tabs>
  </AppBar>
);

const InviteTab: React.FC<{
  value: any;
  index: number;
  users: any[];
  theme: Theme;
  classes: any;
}> = ({ value, index, users, theme, classes }) => (
  <TabPanel value={value} index={index}>
    <List dense className={classes.list}>
      {users.map(invitee => (
        <UserRow user={invitee.user} key={invitee.user.id} classes={classes} />
      ))}
      {users.length === 0 && <NoUsersRow classes={classes} />}
    </List>
  </TabPanel>
);

const NoUsersRow: React.FC<{ classes: any }> = ({ classes }) => (
  <ListItem className={classes.listItem}>
    <ListItemText
      primaryTypographyProps={listItemTextProps}
      primary={`No Users.`}
    />
  </ListItem>
);

const UserRow: React.FC<{ classes: any; user: any }> = ({ classes, user }) => (
  <ListItem className={classes.listItem}>
    <ListItemAvatar>
      <Avatar className={classes.icon} src={user.img}></Avatar>
    </ListItemAvatar>
    <ListItemText
      primaryTypographyProps={listItemTextProps}
      primary={user.username}
    />
  </ListItem>
);

const TabPanel: React.FC<{ value: any; index: number }> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <CardContent> {children}</CardContent>
    </Typography>
  );
};

const a11yProps = (index: number) => ({
  id: `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`
});

export default WhoCard;
