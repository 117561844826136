import React from 'react';

import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  TextField,
  InputAdornment,
  MenuItem,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';

import SocialIcon, { getSocialColor } from 'components/core/Icons';
import Spacer from 'components/core/Spacer';

import { linkTypes, listItemTextProps } from './shared';
import { useLinkReducer } from './store';
import {
  CLEAR_LINK,
  CHANGE_LINK_TYPE,
  CHANGE_LINK_VALUE
} from './store/links.actions';

const SetLinksCard: React.FC<{ classes: any; linksState: any }> = ({
  classes,
  linksState
}) => {
  const [links, setLinks] = linksState;

  const [linkData, dispatch] = useLinkReducer();

  const { value, type, prefix, placeholder } = linkData;

  const isValid = value && value !== '';

  const handleLinkTypeChange = (event: any) => {
    const link = linkTypes.find(l => l.type === event.target.value);
    dispatch({
      type: CHANGE_LINK_TYPE,
      payload: {
        type: link?.type,
        prefix: link?.prefix,
        placeholder: link?.placeholder
      }
    });
  };

  const handleAddLinks = () => {
    setLinks([...links, { type, url: prefix + value, name: value }]);
    dispatch({ type: CLEAR_LINK });
  };

  const removeLink = (url: string) => {
    setLinks(links.filter((l: any) => l.url !== url));
  };

  const showLinks = links && links.length !== 0;

  return (
    <Card>
      <CardHeader title="Links" className={classes.header} />
      <CardContent>
        <TextField
          label="URL"
          placeholder={placeholder}
          value={value}
          fullWidth
          onChange={e =>
            dispatch({ type: CHANGE_LINK_VALUE, payload: e.target.value })
          }
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{prefix}</InputAdornment>
            )
          }}
        />
        <TextField
          select
          label="Link Type"
          fullWidth
          margin="normal"
          value={type}
          onChange={handleLinkTypeChange}
        >
          {linkTypes.map(option => (
            <MenuItem key={option.type} value={option.type}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Spacer size="20" />
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          disabled={!isValid}
          onClick={handleAddLinks}
        >
          Add Link
        </Button>
        <Spacer size="20" hidden={!showLinks} />
        <LinksList
          classes={classes}
          links={links}
          showLinks={showLinks}
          removeLink={removeLink}
        />
      </CardContent>
    </Card>
  );
};

const LinksList: React.FC<{
  links: any[];
  showLinks: boolean;
  removeLink: any;
  classes: any;
}> = ({ links, showLinks, removeLink, classes }) => {
  if (!showLinks) return null;

  return (
    <List dense className={classes.list}>
      {links.map(link => (
        <SocialLink
          link={link}
          key={link.url}
          classes={classes}
          onRemove={() => removeLink(link.url)}
        />
      ))}
    </List>
  );
};

const SocialLink: React.FC<{ link: any; onRemove: any; classes: any }> = ({
  link,
  onRemove,
  classes
}) => (
  <ListItem className={classes.listItem}>
    <ListItemAvatar>
      <Avatar
        className={classes.icon}
        style={{
          background: getSocialColor(link.type)
        }}
      >
        <SocialIcon
          type={link.type}
          color="action"
          className={classes.customIcon}
        />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primaryTypographyProps={listItemTextProps}
      primary={link.name}
    />
    <ListItemSecondaryAction>
      <IconButton edge="end" onClick={onRemove}>
        <TrashIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);

export default SetLinksCard;
