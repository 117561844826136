import React from 'react';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

type TextColorType = 'primary' | 'secondary' | 'disabled' | 'hint';

const useStyles = makeStyles(theme => ({
  root: (props: { text?: TextColorType }) => {
    return {
      color: props.text
        ? theme.palette.text[props.text] ?? theme.palette.text.primary
        : theme.palette.text.primary
    };
  }
}));

const Button: React.FC<ButtonProps & {
  to?: string;
  text?: TextColorType;
  component?: any;
}> = ({ children, ...rest }) => {
  const classes = useStyles(rest);

  return (
    <MuiButton className={classes.root} {...rest}>
      {children}
    </MuiButton>
  );
};

export default Button;
