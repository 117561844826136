import React from 'react';

import { Box } from '@material-ui/core';

import Spacer from 'components/core/Spacer';
import Loader from 'components/core/Loader';
import GameHeader from 'components/gametimes/GameHeader';
import {
  DetailsCard,
  WhereCard,
  WhoCard,
  useStyles
} from 'components/gametime';
import { useGetGametimeByIdQuery } from 'generated/schemaTypes';

const Gametime: React.FC<{ match: any }> = ({ match }) => {
  const gametimeID = match.params.id;
  const classes = useStyles();

  const { loading, error, data } = useGetGametimeByIdQuery({
    variables: { gametimeID }
  });

  if (loading) return <Loader loading={true} />;
  if (error) return <span>`Error! ${error.message}`</span>;
  if (!data?.gametimeById) return <span>No Gametime!</span>;

  const gametime = data.gametimeById;

  const coverSet = {
    med: gametime.game.images?.screenshotMed,
    big: gametime.game.images?.screenshotBig,
    huge: gametime.game.images?.screenshotHuge
  };

  const shouldShowWhereCard =
    !!gametime.socialLinks && gametime.socialLinks.length >= 1;

  return (
    <React.Fragment>
      <Box marginX={-2} marginTop={-2} style={{ position: 'relative' }}>
        <GameHeader imageSet={coverSet} position="fixed" />
      </Box>
      <div className={classes.content}>
        <DetailsCard
          classes={classes}
          title={gametime.title}
          game={gametime.game.name}
          date={gametime.date}
        />
        <Spacer size={3} hidden={!shouldShowWhereCard} />
        <WhereCard
          classes={classes}
          links={gametime.socialLinks ?? []}
          show={shouldShowWhereCard}
        />
        <Spacer size={3} />
        <WhoCard classes={classes} users={gametime.users} />
      </div>
    </React.Fragment>
  );
};

export default Gametime;
