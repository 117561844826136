import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';

import { useAuth0 } from 'react-auth0-spa';
import { userIDFromSub } from 'utils';

const withApollo = (InputComponent: React.FC) => {
  const EnhancedComponent: React.FC = () => {
    const { loading, user } = useAuth0();

    if (loading) return null;

    const client = new ApolloClient({
      uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      headers: {
        authorization: userIDFromSub(user?.sub)
      }
    });

    return (
      <ApolloProvider client={client}>
        <InputComponent />
      </ApolloProvider>
    );
  };

  return EnhancedComponent;
};

export default withApollo;
