const routes = [
  {
    label: 'Home',
    link: '/',
    private: false
  },
  {
    label: 'Profile',
    link: '/profile',
    private: true
  },
  {
    label: 'Gametimes',
    link: '/gametimes',
    private: true
  }
];

export default routes;
