import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import BackgroundSlider from 'react-background-slider';
import { IdentityContext } from 'contexts';
import { useGetHeaderInfoQuery } from 'generated/schemaTypes';

const ProfileHeader: React.FC<{ classes: any }> = ({ classes }) => {
  const identity = useContext(IdentityContext);
  const { loading, data } = useGetHeaderInfoQuery();
  const showHeader = data?.me?.gametimes && !loading;

  const headerInfo = data?.me?.gametimes ?? [];

  const imageSet = showHeader
    ? headerInfo.reduce<string[]>((unique, item) => {
        if (!item.game.images?.screenshotBig) return unique;
        const img = item.game.images.screenshotBig;
        return unique.indexOf(img) ? [...unique, img] : unique;
      }, [])
    : [];

  const avatar = identity.me?.img;

  return (
    <div className={classes.gameBanner}>
      {showHeader && (
        <div className={classes.backgroundWrapper}>
          <BackgroundSlider images={imageSet} duration={8} transition={2} />
        </div>
      )}
      <div className={classes.backgroundPlaceholder} />
      <div className={classes.avatarRow}>
        <Avatar
          src={avatar}
          style={{
            height: 80,
            width: 80
          }}
        />
      </div>
    </div>
  );
};

export default ProfileHeader;
