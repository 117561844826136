import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';

import GameIcon from '@material-ui/icons/Games';
import TimeIcon from '@material-ui/icons/Event';

import { listItemTextProps } from './shared';

const DetailsCard: React.FC<{
  classes: any;
  title: string;
  game: any;
  date: string;
}> = ({ classes, title, game, date }) => {
  const datetime = new Date(date);
  return (
    <Card className={classes.card}>
      <CardHeader title={title} className={classes.header} />
      <CardContent>
        <List dense className={classes.list}>
          <ListItem className={classes.listItem}>
            <ListItemAvatar>
              <Avatar className={classes.icon}>
                <GameIcon color="action" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={listItemTextProps}
              primary={game}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemAvatar>
              <Avatar className={classes.icon}>
                <TimeIcon color="action" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={listItemTextProps}
              primary={`${datetime.toLocaleDateString()} ${datetime.toLocaleTimeString()}`}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default DetailsCard;
