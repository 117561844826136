import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginTop: theme.spacing(-2),
    position: 'relative'
  },
  card: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    boxShadow: '10px 10px 20px -5px rgba(0,0,0,0.5)'
  },
  content: {
    marginTop: 240,
    position: 'relative'
  },
  header: {
    background: theme.palette.background.cardHeader
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    background: theme.palette.primary.main
  },
  customIcon: {
    height: 20,
    width: 20
  },
  list: {
    padding: 0
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

export default useStyles;
