import { makeStyles } from '@material-ui/core';

export const useClippedStyles = makeStyles((theme: ThemeType) => ({
  container: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0
  },
  clippedImage: {
    backgroundRepeat: 'no-repeat',
    clipPath: 'polygon(100% 0, 0 100%, 0 0)',
    height: '100%',
    position: 'relative',
    width: 264
  },
  overlay: {
    background: 'rgba(0,0,0,0.7)',
    height: '100%',
    position: 'absolute',
    width: '100%'
  }
}));

export const useGameStyles = makeStyles((theme: ThemeType) => ({
  root: {
    height: 100
  },
  gameImg: {
    backgroundPositionX: 'left',
    backgroundPositionY: 'top',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
  }
}));

export const useContentStyles = makeStyles((theme: ThemeType) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 5
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    lineHeight: 'unset'
  },
  time: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column'
  },
  avatars: {
    display: 'flex'
  },
  avatar: {
    height: 24,
    marginRight: theme.spacing(1),
    width: 24
  },
  extra: {
    color: theme.palette.text.primary,
    background: theme.palette.primary.main
  }
}));
