import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  _FieldSet: any,
};






export type About = {
  socialAccounts?: Maybe<Array<SocialLink>>,
};

export type AcceptFriendRequestInput = {
  friendId: Scalars['ID'],
};

export type AcceptFriendRequestPayload = {
  user?: Maybe<User>,
};

export type AcceptGametimeInviteInput = {
  gametimeId: Scalars['ID'],
  response: InviteResponse,
};

export type AcceptGametimeInvitePayload = {
  gametime?: Maybe<Gametime>,
  user?: Maybe<User>,
};

export type CreateFriendRequestInput = {
  friendId: Scalars['ID'],
};

export type CreateFriendRequestPayload = {
  friendRequest?: Maybe<FriendRequest>,
};

export type CreateGametimeInput = {
  gametime: GametimeInput,
};

export type CreateGametimePayload = {
  gametime?: Maybe<Gametime>,
};

export type CreateUserInput = {
  user: UserInput,
};

export type CreateUserPayload = {
  user?: Maybe<User>,
};

export type FriendRequest = {
  id: Scalars['ID'],
  user: User,
  lastModified: Scalars['String'],
  status: RequestStatus,
};

export type FriendRequests = {
  sentRequests?: Maybe<Array<FriendRequest>>,
  recievedRequests?: Maybe<Array<FriendRequest>>,
};

export type Game = {
  id: Scalars['ID'],
  name: Scalars['String'],
  images?: Maybe<GameImage>,
};

export type GameImage = {
  cover?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  thumb?: Maybe<Scalars['String']>,
  screenshotMed?: Maybe<Scalars['String']>,
  screenshotBig?: Maybe<Scalars['String']>,
  screenshotHuge?: Maybe<Scalars['String']>,
  res720?: Maybe<Scalars['String']>,
  res1080?: Maybe<Scalars['String']>,
};

export type Gametime = {
  id: Scalars['ID'],
  title: Scalars['String'],
  date: Scalars['String'],
  users: Array<GametimeUser>,
  game: Game,
  socialLinks?: Maybe<Array<SocialLink>>,
  owner: User,
};

export type GametimeInput = {
  title: Scalars['String'],
  date: Scalars['String'],
  userIds: Array<Scalars['String']>,
  gameId: Scalars['String'],
  socialLinks?: Maybe<Array<SocialLinkInput>>,
};

export type GametimeInvite = {
  id: Scalars['ID'],
  user: User,
  gametime: Gametime,
  lastModified: Scalars['String'],
};

export type GametimeUser = {
  user: User,
  status: InviteStatus,
};

export enum InviteResponse {
  Accept = 'ACCEPT',
  Decline = 'DECLINE'
}

export enum InviteStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Invited = 'INVITED'
}

export enum LinkType {
  Twitch = 'TWITCH',
  Mixer = 'MIXER',
  Discord = 'DISCORD',
  Youtube = 'YOUTUBE',
  Facebook = 'FACEBOOK',
  Plain = 'PLAIN'
}

export type Mutation = {
  createUser?: Maybe<CreateUserPayload>,
  createFriendRequest?: Maybe<CreateFriendRequestPayload>,
  acceptFriendRequest?: Maybe<AcceptFriendRequestPayload>,
  createGametime?: Maybe<CreateGametimePayload>,
  acceptGametimeInvite?: Maybe<AcceptGametimeInvitePayload>,
};


export type MutationCreateUserArgs = {
  input: CreateUserInput
};


export type MutationCreateFriendRequestArgs = {
  input: CreateFriendRequestInput
};


export type MutationAcceptFriendRequestArgs = {
  input: AcceptFriendRequestInput
};


export type MutationCreateGametimeArgs = {
  input: CreateGametimeInput
};


export type MutationAcceptGametimeInviteArgs = {
  input: AcceptGametimeInviteInput
};

export type Query = {
  me?: Maybe<User>,
  userById?: Maybe<User>,
  userByEmail?: Maybe<User>,
  usersBySearch?: Maybe<Array<User>>,
  userByUsername?: Maybe<User>,
  gametimesByOwner?: Maybe<Array<Gametime>>,
  gametimeById?: Maybe<Gametime>,
  gamesBySearch?: Maybe<Array<Game>>,
  gameById?: Maybe<Game>,
};


export type QueryUserByIdArgs = {
  id: Scalars['ID']
};


export type QueryUserByEmailArgs = {
  email: Scalars['String']
};


export type QueryUsersBySearchArgs = {
  search: Scalars['String']
};


export type QueryUserByUsernameArgs = {
  username: Scalars['String']
};


export type QueryGametimesByOwnerArgs = {
  id: Scalars['ID'],
  from?: Maybe<Scalars['String']>,
  to?: Maybe<Scalars['String']>,
  top?: Maybe<Scalars['Int']>
};


export type QueryGametimeByIdArgs = {
  id: Scalars['ID']
};


export type QueryGamesBySearchArgs = {
  search: Scalars['String']
};


export type QueryGameByIdArgs = {
  id: Scalars['ID']
};

export enum RequestStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type SocialLink = {
  url: Scalars['String'],
  name: Scalars['String'],
  type: LinkType,
};

export type SocialLinkInput = {
  url: Scalars['String'],
  name: Scalars['String'],
  type: LinkType,
};

export type User = {
  id: Scalars['ID'],
  about?: Maybe<About>,
  email: Scalars['String'],
  username: Scalars['String'],
  img: Scalars['String'],
  friends?: Maybe<Array<User>>,
  friendRequests?: Maybe<FriendRequests>,
  gametimes?: Maybe<Array<Gametime>>,
  gametimeInvites?: Maybe<Array<GametimeInvite>>,
};


export type UserFriendsArgs = {
  search?: Maybe<Scalars['String']>
};


export type UserGametimesArgs = {
  from?: Maybe<Scalars['String']>,
  to?: Maybe<Scalars['String']>,
  top?: Maybe<Scalars['Int']>
};

export type UserInput = {
  id: Scalars['String'],
  img: Scalars['String'],
  username: Scalars['String'],
  email: Scalars['String'],
};

export type GetIdentityQueryVariables = {};


export type GetIdentityQuery = { me: Maybe<Pick<User, 'id' | 'img' | 'username'>> };

export type SearchGamesQueryVariables = {
  search: Scalars['String']
};


export type SearchGamesQuery = { gamesBySearch: Maybe<Array<(
    Pick<Game, 'id' | 'name'>
    & { images: Maybe<Pick<GameImage, 'cover' | 'logo' | 'thumb' | 'screenshotMed' | 'screenshotBig' | 'screenshotHuge' | 'res720' | 'res1080'>> }
  )>> };

export type CreateGametimeMutationVariables = {
  input: CreateGametimeInput
};


export type CreateGametimeMutation = { createGametime: Maybe<{ gametime: Maybe<Pick<Gametime, 'id'>> }> };

export type SearchFriendsQueryVariables = {
  search: Scalars['String']
};


export type SearchFriendsQuery = { me: Maybe<(
    Pick<User, 'id'>
    & { friends: Maybe<Array<Pick<User, 'id' | 'img' | 'username'>>> }
  )> };

export type SearchUsersQueryVariables = {
  search: Scalars['String']
};


export type SearchUsersQuery = { usersBySearch: Maybe<Array<Pick<User, 'id' | 'username' | 'img'>>> };

export type GetFriendsAndRequestsQueryVariables = {};


export type GetFriendsAndRequestsQuery = { me: Maybe<(
    Pick<User, 'id'>
    & { friends: Maybe<Array<Pick<User, 'id' | 'username' | 'img'>>>, friendRequests: Maybe<{ sentRequests: Maybe<Array<(
        Pick<FriendRequest, 'status' | 'lastModified'>
        & { user: Pick<User, 'id' | 'username' | 'img'> }
      )>>, recievedRequests: Maybe<Array<(
        Pick<FriendRequest, 'status' | 'lastModified'>
        & { user: Pick<User, 'id' | 'username' | 'img'> }
      )>> }> }
  )> };

export type SendFriendRequestMutationVariables = {
  input: CreateFriendRequestInput
};


export type SendFriendRequestMutation = { createFriendRequest: Maybe<{ friendRequest: Maybe<Pick<FriendRequest, 'id'>> }> };

export type AcceptFriendRequestMutationVariables = {
  input: AcceptFriendRequestInput
};


export type AcceptFriendRequestMutation = { acceptFriendRequest: Maybe<{ user: Maybe<Pick<User, 'id'>> }> };

export type GetGametimeByIdQueryVariables = {
  gametimeID: Scalars['ID']
};


export type GetGametimeByIdQuery = { gametimeById: Maybe<(
    Pick<Gametime, 'id' | 'title' | 'date'>
    & { users: Array<(
      Pick<GametimeUser, 'status'>
      & { user: Pick<User, 'id' | 'img' | 'username'> }
    )>, game: (
      Pick<Game, 'id' | 'name'>
      & { images: Maybe<Pick<GameImage, 'screenshotMed' | 'screenshotBig' | 'screenshotHuge'>> }
    ), socialLinks: Maybe<Array<Pick<SocialLink, 'type' | 'url' | 'name'>>> }
  )> };

export type GetUpcomingGametimesQueryVariables = {};


export type GetUpcomingGametimesQuery = { me: Maybe<(
    Pick<User, 'id'>
    & { gametimes: Maybe<Array<(
      Pick<Gametime, 'id' | 'title' | 'date'>
      & { users: Array<{ user: Pick<User, 'id' | 'username' | 'img'> }>, game: (
        Pick<Game, 'id' | 'name'>
        & { images: Maybe<Pick<GameImage, 'cover' | 'screenshotMed' | 'screenshotBig' | 'screenshotHuge'>> }
      ) }
    )>> }
  )> };

export type GetGametimeInvitesQueryVariables = {};


export type GetGametimeInvitesQuery = { me: Maybe<(
    Pick<User, 'id'>
    & { gametimeInvites: Maybe<Array<(
      Pick<GametimeInvite, 'id'>
      & { user: Pick<User, 'id' | 'username' | 'img'>, gametime: (
        Pick<Gametime, 'id' | 'title'>
        & { game: (
          Pick<Game, 'id' | 'name'>
          & { images: Maybe<Pick<GameImage, 'thumb'>> }
        ) }
      ) }
    )>> }
  )> };

export type AcceptGametimeInviteMutationVariables = {
  input: AcceptGametimeInviteInput
};


export type AcceptGametimeInviteMutation = { acceptGametimeInvite: Maybe<{ gametime: Maybe<Pick<Gametime, 'id'>>, user: Maybe<(
      Pick<User, 'id'>
      & { gametimeInvites: Maybe<Array<Pick<GametimeInvite, 'id'>>> }
    )> }> };

export type GetHeaderInfoQueryVariables = {};


export type GetHeaderInfoQuery = { me: Maybe<(
    Pick<User, 'id'>
    & { gametimes: Maybe<Array<{ game: (
        Pick<Game, 'id'>
        & { images: Maybe<Pick<GameImage, 'screenshotBig'>> }
      ) }>> }
  )> };

export type GetRecentGamesQueryVariables = {};


export type GetRecentGamesQuery = { me: Maybe<(
    Pick<User, 'id'>
    & { gametimes: Maybe<Array<(
      Pick<Gametime, 'id' | 'title' | 'date'>
      & { game: Pick<Game, 'id' | 'name'> }
    )>> }
  )> };


export const GetIdentityDocument = gql`
    query GetIdentity {
  me {
    id
    img
    username
  }
}
    `;

/**
 * __useGetIdentityQuery__
 *
 * To run a query within a React component, call `useGetIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIdentityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIdentityQuery, GetIdentityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIdentityQuery, GetIdentityQueryVariables>(GetIdentityDocument, baseOptions);
      }
export function useGetIdentityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIdentityQuery, GetIdentityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIdentityQuery, GetIdentityQueryVariables>(GetIdentityDocument, baseOptions);
        }
export type GetIdentityQueryHookResult = ReturnType<typeof useGetIdentityQuery>;
export type GetIdentityLazyQueryHookResult = ReturnType<typeof useGetIdentityLazyQuery>;
export type GetIdentityQueryResult = ApolloReactCommon.QueryResult<GetIdentityQuery, GetIdentityQueryVariables>;
export const SearchGamesDocument = gql`
    query SearchGames($search: String!) {
  gamesBySearch(search: $search) {
    id
    name
    images {
      cover
      logo
      thumb
      screenshotMed
      screenshotBig
      screenshotHuge
      res720
      res1080
    }
  }
}
    `;

/**
 * __useSearchGamesQuery__
 *
 * To run a query within a React component, call `useSearchGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGamesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchGamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchGamesQuery, SearchGamesQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchGamesQuery, SearchGamesQueryVariables>(SearchGamesDocument, baseOptions);
      }
export function useSearchGamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchGamesQuery, SearchGamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchGamesQuery, SearchGamesQueryVariables>(SearchGamesDocument, baseOptions);
        }
export type SearchGamesQueryHookResult = ReturnType<typeof useSearchGamesQuery>;
export type SearchGamesLazyQueryHookResult = ReturnType<typeof useSearchGamesLazyQuery>;
export type SearchGamesQueryResult = ApolloReactCommon.QueryResult<SearchGamesQuery, SearchGamesQueryVariables>;
export const CreateGametimeDocument = gql`
    mutation CreateGametime($input: CreateGametimeInput!) {
  createGametime(input: $input) {
    gametime {
      id
    }
  }
}
    `;
export type CreateGametimeMutationFn = ApolloReactCommon.MutationFunction<CreateGametimeMutation, CreateGametimeMutationVariables>;

/**
 * __useCreateGametimeMutation__
 *
 * To run a mutation, you first call `useCreateGametimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGametimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGametimeMutation, { data, loading, error }] = useCreateGametimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGametimeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGametimeMutation, CreateGametimeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGametimeMutation, CreateGametimeMutationVariables>(CreateGametimeDocument, baseOptions);
      }
export type CreateGametimeMutationHookResult = ReturnType<typeof useCreateGametimeMutation>;
export type CreateGametimeMutationResult = ApolloReactCommon.MutationResult<CreateGametimeMutation>;
export type CreateGametimeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGametimeMutation, CreateGametimeMutationVariables>;
export const SearchFriendsDocument = gql`
    query SearchFriends($search: String!) {
  me {
    id
    friends(search: $search) {
      id
      img
      username
    }
  }
}
    `;

/**
 * __useSearchFriendsQuery__
 *
 * To run a query within a React component, call `useSearchFriendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFriendsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFriendsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchFriendsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchFriendsQuery, SearchFriendsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchFriendsQuery, SearchFriendsQueryVariables>(SearchFriendsDocument, baseOptions);
      }
export function useSearchFriendsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchFriendsQuery, SearchFriendsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchFriendsQuery, SearchFriendsQueryVariables>(SearchFriendsDocument, baseOptions);
        }
export type SearchFriendsQueryHookResult = ReturnType<typeof useSearchFriendsQuery>;
export type SearchFriendsLazyQueryHookResult = ReturnType<typeof useSearchFriendsLazyQuery>;
export type SearchFriendsQueryResult = ApolloReactCommon.QueryResult<SearchFriendsQuery, SearchFriendsQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($search: String!) {
  usersBySearch(search: $search) {
    id
    username
    img
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
      }
export function useSearchUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = ApolloReactCommon.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const GetFriendsAndRequestsDocument = gql`
    query GetFriendsAndRequests {
  me {
    id
    friends {
      id
      username
      img
    }
    friendRequests {
      sentRequests {
        user {
          id
          username
          img
        }
        status
        lastModified
      }
      recievedRequests {
        user {
          id
          username
          img
        }
        status
        lastModified
      }
    }
  }
}
    `;

/**
 * __useGetFriendsAndRequestsQuery__
 *
 * To run a query within a React component, call `useGetFriendsAndRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFriendsAndRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFriendsAndRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFriendsAndRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFriendsAndRequestsQuery, GetFriendsAndRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFriendsAndRequestsQuery, GetFriendsAndRequestsQueryVariables>(GetFriendsAndRequestsDocument, baseOptions);
      }
export function useGetFriendsAndRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFriendsAndRequestsQuery, GetFriendsAndRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFriendsAndRequestsQuery, GetFriendsAndRequestsQueryVariables>(GetFriendsAndRequestsDocument, baseOptions);
        }
export type GetFriendsAndRequestsQueryHookResult = ReturnType<typeof useGetFriendsAndRequestsQuery>;
export type GetFriendsAndRequestsLazyQueryHookResult = ReturnType<typeof useGetFriendsAndRequestsLazyQuery>;
export type GetFriendsAndRequestsQueryResult = ApolloReactCommon.QueryResult<GetFriendsAndRequestsQuery, GetFriendsAndRequestsQueryVariables>;
export const SendFriendRequestDocument = gql`
    mutation SendFriendRequest($input: CreateFriendRequestInput!) {
  createFriendRequest(input: $input) {
    friendRequest {
      id
    }
  }
}
    `;
export type SendFriendRequestMutationFn = ApolloReactCommon.MutationFunction<SendFriendRequestMutation, SendFriendRequestMutationVariables>;

/**
 * __useSendFriendRequestMutation__
 *
 * To run a mutation, you first call `useSendFriendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFriendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFriendRequestMutation, { data, loading, error }] = useSendFriendRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendFriendRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendFriendRequestMutation, SendFriendRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<SendFriendRequestMutation, SendFriendRequestMutationVariables>(SendFriendRequestDocument, baseOptions);
      }
export type SendFriendRequestMutationHookResult = ReturnType<typeof useSendFriendRequestMutation>;
export type SendFriendRequestMutationResult = ApolloReactCommon.MutationResult<SendFriendRequestMutation>;
export type SendFriendRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<SendFriendRequestMutation, SendFriendRequestMutationVariables>;
export const AcceptFriendRequestDocument = gql`
    mutation AcceptFriendRequest($input: AcceptFriendRequestInput!) {
  acceptFriendRequest(input: $input) {
    user {
      id
    }
  }
}
    `;
export type AcceptFriendRequestMutationFn = ApolloReactCommon.MutationFunction<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables>;

/**
 * __useAcceptFriendRequestMutation__
 *
 * To run a mutation, you first call `useAcceptFriendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptFriendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptFriendRequestMutation, { data, loading, error }] = useAcceptFriendRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptFriendRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables>(AcceptFriendRequestDocument, baseOptions);
      }
export type AcceptFriendRequestMutationHookResult = ReturnType<typeof useAcceptFriendRequestMutation>;
export type AcceptFriendRequestMutationResult = ApolloReactCommon.MutationResult<AcceptFriendRequestMutation>;
export type AcceptFriendRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptFriendRequestMutation, AcceptFriendRequestMutationVariables>;
export const GetGametimeByIdDocument = gql`
    query GetGametimeById($gametimeID: ID!) {
  gametimeById(id: $gametimeID) {
    id
    title
    date
    users {
      user {
        id
        img
        username
      }
      status
    }
    game {
      id
      name
      images {
        screenshotMed
        screenshotBig
        screenshotHuge
      }
    }
    socialLinks {
      type
      url
      name
    }
  }
}
    `;

/**
 * __useGetGametimeByIdQuery__
 *
 * To run a query within a React component, call `useGetGametimeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGametimeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGametimeByIdQuery({
 *   variables: {
 *      gametimeID: // value for 'gametimeID'
 *   },
 * });
 */
export function useGetGametimeByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGametimeByIdQuery, GetGametimeByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGametimeByIdQuery, GetGametimeByIdQueryVariables>(GetGametimeByIdDocument, baseOptions);
      }
export function useGetGametimeByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGametimeByIdQuery, GetGametimeByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGametimeByIdQuery, GetGametimeByIdQueryVariables>(GetGametimeByIdDocument, baseOptions);
        }
export type GetGametimeByIdQueryHookResult = ReturnType<typeof useGetGametimeByIdQuery>;
export type GetGametimeByIdLazyQueryHookResult = ReturnType<typeof useGetGametimeByIdLazyQuery>;
export type GetGametimeByIdQueryResult = ApolloReactCommon.QueryResult<GetGametimeByIdQuery, GetGametimeByIdQueryVariables>;
export const GetUpcomingGametimesDocument = gql`
    query GetUpcomingGametimes {
  me {
    id
    gametimes(from: "now") {
      id
      title
      date
      users {
        user {
          id
          username
          img
        }
      }
      game {
        id
        name
        images {
          cover
          screenshotMed
          screenshotBig
          screenshotHuge
        }
      }
    }
  }
}
    `;

/**
 * __useGetUpcomingGametimesQuery__
 *
 * To run a query within a React component, call `useGetUpcomingGametimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingGametimesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingGametimesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUpcomingGametimesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUpcomingGametimesQuery, GetUpcomingGametimesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUpcomingGametimesQuery, GetUpcomingGametimesQueryVariables>(GetUpcomingGametimesDocument, baseOptions);
      }
export function useGetUpcomingGametimesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUpcomingGametimesQuery, GetUpcomingGametimesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUpcomingGametimesQuery, GetUpcomingGametimesQueryVariables>(GetUpcomingGametimesDocument, baseOptions);
        }
export type GetUpcomingGametimesQueryHookResult = ReturnType<typeof useGetUpcomingGametimesQuery>;
export type GetUpcomingGametimesLazyQueryHookResult = ReturnType<typeof useGetUpcomingGametimesLazyQuery>;
export type GetUpcomingGametimesQueryResult = ApolloReactCommon.QueryResult<GetUpcomingGametimesQuery, GetUpcomingGametimesQueryVariables>;
export const GetGametimeInvitesDocument = gql`
    query GetGametimeInvites {
  me {
    id
    gametimeInvites {
      id
      user {
        id
        username
        img
      }
      gametime {
        id
        title
        game {
          id
          name
          images {
            thumb
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetGametimeInvitesQuery__
 *
 * To run a query within a React component, call `useGetGametimeInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGametimeInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGametimeInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGametimeInvitesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGametimeInvitesQuery, GetGametimeInvitesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGametimeInvitesQuery, GetGametimeInvitesQueryVariables>(GetGametimeInvitesDocument, baseOptions);
      }
export function useGetGametimeInvitesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGametimeInvitesQuery, GetGametimeInvitesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGametimeInvitesQuery, GetGametimeInvitesQueryVariables>(GetGametimeInvitesDocument, baseOptions);
        }
export type GetGametimeInvitesQueryHookResult = ReturnType<typeof useGetGametimeInvitesQuery>;
export type GetGametimeInvitesLazyQueryHookResult = ReturnType<typeof useGetGametimeInvitesLazyQuery>;
export type GetGametimeInvitesQueryResult = ApolloReactCommon.QueryResult<GetGametimeInvitesQuery, GetGametimeInvitesQueryVariables>;
export const AcceptGametimeInviteDocument = gql`
    mutation AcceptGametimeInvite($input: AcceptGametimeInviteInput!) {
  acceptGametimeInvite(input: $input) {
    gametime {
      id
    }
    user {
      id
      gametimeInvites {
        id
      }
    }
  }
}
    `;
export type AcceptGametimeInviteMutationFn = ApolloReactCommon.MutationFunction<AcceptGametimeInviteMutation, AcceptGametimeInviteMutationVariables>;

/**
 * __useAcceptGametimeInviteMutation__
 *
 * To run a mutation, you first call `useAcceptGametimeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptGametimeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptGametimeInviteMutation, { data, loading, error }] = useAcceptGametimeInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptGametimeInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptGametimeInviteMutation, AcceptGametimeInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptGametimeInviteMutation, AcceptGametimeInviteMutationVariables>(AcceptGametimeInviteDocument, baseOptions);
      }
export type AcceptGametimeInviteMutationHookResult = ReturnType<typeof useAcceptGametimeInviteMutation>;
export type AcceptGametimeInviteMutationResult = ApolloReactCommon.MutationResult<AcceptGametimeInviteMutation>;
export type AcceptGametimeInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptGametimeInviteMutation, AcceptGametimeInviteMutationVariables>;
export const GetHeaderInfoDocument = gql`
    query GetHeaderInfo {
  me {
    id
    gametimes {
      game {
        id
        images {
          screenshotBig
        }
      }
    }
  }
}
    `;

/**
 * __useGetHeaderInfoQuery__
 *
 * To run a query within a React component, call `useGetHeaderInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeaderInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeaderInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHeaderInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHeaderInfoQuery, GetHeaderInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHeaderInfoQuery, GetHeaderInfoQueryVariables>(GetHeaderInfoDocument, baseOptions);
      }
export function useGetHeaderInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHeaderInfoQuery, GetHeaderInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHeaderInfoQuery, GetHeaderInfoQueryVariables>(GetHeaderInfoDocument, baseOptions);
        }
export type GetHeaderInfoQueryHookResult = ReturnType<typeof useGetHeaderInfoQuery>;
export type GetHeaderInfoLazyQueryHookResult = ReturnType<typeof useGetHeaderInfoLazyQuery>;
export type GetHeaderInfoQueryResult = ApolloReactCommon.QueryResult<GetHeaderInfoQuery, GetHeaderInfoQueryVariables>;
export const GetRecentGamesDocument = gql`
    query GetRecentGames {
  me {
    id
    gametimes {
      id
      title
      date
      game {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetRecentGamesQuery__
 *
 * To run a query within a React component, call `useGetRecentGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentGamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecentGamesQuery, GetRecentGamesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecentGamesQuery, GetRecentGamesQueryVariables>(GetRecentGamesDocument, baseOptions);
      }
export function useGetRecentGamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecentGamesQuery, GetRecentGamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecentGamesQuery, GetRecentGamesQueryVariables>(GetRecentGamesDocument, baseOptions);
        }
export type GetRecentGamesQueryHookResult = ReturnType<typeof useGetRecentGamesQuery>;
export type GetRecentGamesLazyQueryHookResult = ReturnType<typeof useGetRecentGamesLazyQuery>;
export type GetRecentGamesQueryResult = ApolloReactCommon.QueryResult<GetRecentGamesQuery, GetRecentGamesQueryVariables>;