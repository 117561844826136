import React from 'react';
import DiscordIcon, { discordColor } from './DiscordIcon';
import MixerIcon, { mixerColor } from './MixerIcon';
import TwitchIcon, { twitchColor } from './TwitchIcon';
import YoutubeIcon, { youtubeColor } from './YoutubeIcon';
import FacebookIcon, { facebookColor } from './FacebookIcon';
import LinkIcon, { linkColor } from './LinkIcon';
import { SvgIconProps } from '@material-ui/core';
import { LinkType } from 'generated/schemaTypes';

const SocialIcon = ({
  type,
  ...rest
}: SvgIconProps & {
  type: LinkType;
}) => {
  switch (type) {
    case 'TWITCH':
      return <TwitchIcon {...rest} />;
    case 'MIXER':
      return <MixerIcon {...rest} />;
    case 'DISCORD':
      return <DiscordIcon {...rest} />;
    case 'YOUTUBE':
      return <YoutubeIcon {...rest} />;
    case 'FACEBOOK':
      return <FacebookIcon {...rest} />;
    case 'PLAIN':
    default:
      return <LinkIcon {...rest} />;
  }
};

const getSocialColor = (type: LinkType) => {
  switch (type) {
    case 'TWITCH':
      return twitchColor;
    case 'MIXER':
      return mixerColor;
    case 'DISCORD':
      return discordColor;
    case 'YOUTUBE':
      return youtubeColor;
    case 'FACEBOOK':
      return facebookColor;
    case 'PLAIN':
    default:
      return linkColor;
  }
};

export default SocialIcon;
export { SocialIcon, getSocialColor };
