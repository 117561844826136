import React from 'react';
import Spinner from 'react-loader-spinner';
import styled from 'styled-components';
import { Box, LinearProgress, Paper } from '@material-ui/core';

const Loader: React.FC<{ loading: boolean }> = ({ loading }) => {
  return loading ? (
    <LoaderContainer>
      <Spinner type="Triangle" color="#9B51E0" height={100} width={100} />
    </LoaderContainer>
  ) : null;
};

const ListLoader: React.FC<{ loading: boolean }> = ({ loading }) => {
  return loading ? (
    <Paper>
      <Box p={3}>
        <LinearProgress></LinearProgress>
      </Box>
    </Paper>
  ) : null;
};

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 3;
`;

export default Loader;
export { Loader, ListLoader };
