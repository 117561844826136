import React from 'react';

import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import EmptyGameCard from './EmptyGametime';
import GameCard from './../core/GameCard';
import Spacer from './../core/Spacer';

const UpcomingGametimes: React.FC<{
  gametimes: any[];
  hasUpcomingGames: boolean;
}> = ({ gametimes, hasUpcomingGames }) => {
  if (!hasUpcomingGames) return <EmptyGameCard />;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Upcoming Games
      </Typography>
      {gametimes.map(gametime => (
        <React.Fragment key={gametime.id}>
          <GameCard
            gametime={gametime}
            actionAreaProps={{
              component: Link,
              to: `/gametimes/${gametime.id}`
            }}
          />
          <Spacer size={3} />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default UpcomingGametimes;
